import { _ as _async_to_generator } from "@swc/helpers/_/_async_to_generator";
import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import * as getAllNewPurchaseOrderActions from "../../slices/logic/getAllNewPurchaseOrder.slice";
import { http } from "utils/http";
import { endPoint } from "constants/server/api";
export var getAllNewPurchaseOrder = function(param) {
    var modalObj = param.modalObj, setModalObj = param.setModalObj;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var accessToken, config, result, error, statusCode;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        dispatch(getAllNewPurchaseOrderActions.fetchData());
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            ,
                            4
                        ]);
                        accessToken = localStorage.getItem("accessToken");
                        config = {
                            method: "get",
                            headers: {
                                "Authorization": "Bearer ".concat(accessToken)
                            },
                            url: endPoint.NEW_PURCHASE_ORDER
                        };
                        return [
                            4,
                            http(config)
                        ];
                    case 2:
                        result = _state.sent();
                        dispatch(getAllNewPurchaseOrderActions.fetchDataSuccess(result.data));
                        return [
                            3,
                            4
                        ];
                    case 3:
                        error = _state.sent();
                        if (http.isAxiosError(error) && error.response) {
                            statusCode = error.response.status;
                            setModalObj(_object_spread_props(_object_spread({}, modalObj), {
                                visible: true,
                                subTitle: "รหัส ".concat(statusCode),
                                navigateDirection: undefined,
                                title: "".concat(error.response.data.message)
                            }));
                        } else {
                            setModalObj(_object_spread_props(_object_spread({}, modalObj), {
                                visible: true,
                                subTitle: "รหัส 500",
                                navigateDirection: undefined,
                                title: "เกิดข้อผิดพลาด\nหรือเครือข่ายขัดข้อง"
                            }));
                        }
                        dispatch(getAllNewPurchaseOrderActions.fetchDataFailure());
                        return [
                            3,
                            4
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
