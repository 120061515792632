import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    data: null,
    isFetching: false,
    isError: false
};
var getAllMstBankAccountTypeSlice = createSlice({
    name: "getAllMstBankAccountType",
    initialState: initialState,
    reducers: {
        fetchData: function(state) {
            state.isFetching = true;
            state.data = null;
            state.isError = false;
        },
        fetchDataSuccess: function(state, action) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                data: action.payload
            });
        },
        fetchDataFailure: function(state) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                isError: true
            });
        },
        resetState: function() {
            return initialState;
        }
    }
});
var _getAllMstBankAccountTypeSlice_actions = getAllMstBankAccountTypeSlice.actions;
export var fetchData = _getAllMstBankAccountTypeSlice_actions.fetchData, fetchDataSuccess = _getAllMstBankAccountTypeSlice_actions.fetchDataSuccess, fetchDataFailure = _getAllMstBankAccountTypeSlice_actions.fetchDataFailure, resetState = _getAllMstBankAccountTypeSlice_actions.resetState;
export default getAllMstBankAccountTypeSlice.reducer;
