import moment from "moment";
import { getSupplierFullName, getThaiMonths, NumberFormat } from "./HelperFunction";
export function mappingProductHistoryBuyList(dimNewPoItemList) {
    var mappingData = dimNewPoItemList.map(function(v) {
        var id = v.id, dimProductPrice = v.dimProductPrice, dimMasterUnit = v.dimMasterUnit, dimNewPurchaseOrder = v.dimNewPurchaseOrder, doc_issue_date = v.doc_issue_date, quantity = v.quantity, unit_price = v.unit_price, ratio_unit = v.ratio_unit, discount_price = v.discount_price, total_price_include_vat = v.total_price_include_vat;
        return {
            id: id,
            documentCode: dimNewPurchaseOrder.doc_number,
            orderDate: moment(doc_issue_date).format("DD/MM/YYYY"),
            distributor: getSupplierFullName(dimNewPurchaseOrder.dimSupplierInformation),
            quantity: quantity,
            barcode: dimProductPrice.barcode,
            productUnit: dimMasterUnit.name,
            productRatioUnit: ratio_unit,
            pricePerUnit: unit_price,
            discountPerUnit: discount_price,
            total: total_price_include_vat
        };
    });
    return mappingData;
}
export function mappingProductHistorySaleList(saleHistory) {
    var thaiMonths = getThaiMonths();
    var mappingData = saleHistory.map(function(v) {
        var month = v.month, sumQty = v.sumQty, ratePercent = v.ratePercent;
        var monthName = thaiMonths[month];
        return {
            monthName: monthName,
            sumQty: sumQty,
            ratePercent: NumberFormat(v.ratePercent)
        };
    });
    return mappingData;
}
export function mappingProductHistoryPrimary(productPrice) {
    var _productPrice_dimProduct = productPrice.dimProduct, name_th = _productPrice_dimProduct.name_th, dimMasterSubUnit = _productPrice_dimProduct.dimMasterSubUnit, dimMasterUnit = _productPrice_dimProduct.dimMasterUnit, ratio_sub_unit = _productPrice_dimProduct.ratio_sub_unit;
    var umsName = dimMasterSubUnit ? dimMasterSubUnit.name : "-";
    var umsRatio = 1;
    var umlName = dimMasterUnit ? dimMasterUnit.name : "-";
    var umlRatio = ratio_sub_unit !== null && ratio_sub_unit !== void 0 ? ratio_sub_unit : "-";
    return {
        productName: name_th,
        umsName: umsName,
        umsRatio: umsRatio,
        umlName: umlName,
        umlRatio: umlRatio
    };
}
