import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    data: null,
    isFetching: false,
    isError: false
};
var getAllMstMaritalStatusSlice = createSlice({
    name: "getAllMstMaritalStatus",
    initialState: initialState,
    reducers: {
        fetchData: function(state) {
            state.isFetching = true;
            state.data = null;
            state.isError = false;
        },
        fetchDataSuccess: function(state, action) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                data: action.payload
            });
        },
        fetchDataFailure: function(state) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                isError: true
            });
        },
        resetState: function() {
            return initialState;
        }
    }
});
var _getAllMstMaritalStatusSlice_actions = getAllMstMaritalStatusSlice.actions;
export var fetchData = _getAllMstMaritalStatusSlice_actions.fetchData, fetchDataSuccess = _getAllMstMaritalStatusSlice_actions.fetchDataSuccess, fetchDataFailure = _getAllMstMaritalStatusSlice_actions.fetchDataFailure, resetState = _getAllMstMaritalStatusSlice_actions.resetState;
export default getAllMstMaritalStatusSlice.reducer;
