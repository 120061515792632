import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { nanoid } from "@reduxjs/toolkit";
import { getFullUrlPath } from "./HandleFileFunction";
export function mappingPr(param) {
    var prData = param.prData, setPdata = param.setPdata, setProductRow = param.setProductRow, setFormdisabled = param.setFormdisabled, columnsort = param.columnsort, setColumnsort = param.setColumnsort, setAttachments = param.setAttachments, setTagSelected = param.setTagSelected;
    var dataWithReplacements = Object.entries(prData).reduce(function(acc, param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
        acc[key] = value === null ? "" : value;
        return acc;
    }, {});
    if (dataWithReplacements.dimSupplierInformation) {
        var dimSupplierInformation = dataWithReplacements.dimSupplierInformation;
        var full_supplier_name = dataWithReplacements.full_supplier_name, full_supplier_address_name = dataWithReplacements.full_supplier_address_name;
        setPdata(function(prev) {
            return {
                id: dimSupplierInformation.id,
                dimGroupOfSupplierCode: dimSupplierInformation.dimGroupOfSupplier ? dimSupplierInformation.dimGroupOfSupplier.code : "-",
                full_supplier_name: full_supplier_name,
                initials: dimSupplierInformation.initials,
                company_branch_code: dimSupplierInformation.company_branch_code,
                company_branch_name: dimSupplierInformation.company_branch_name,
                full_supplier_address_name: full_supplier_address_name,
                company_phone_number: dimSupplierInformation.company_phone_number
            };
        });
    }
    if (dataWithReplacements.dimDocFilePr && dataWithReplacements.dimDocFilePr.length > 0) {
        var attchments = dataWithReplacements.dimDocFilePr.map(function(v, i) {
            var newId = nanoid();
            var type = v.original_file_name.split(".");
            var mappingData = {
                id: newId,
                docId: v.id,
                docFile: v.original_file_name,
                docFileType: type[type.length - 1],
                docFileData: "",
                docUrl: getFullUrlPath(v.file_path)
            };
            return mappingData;
        });
        setAttachments(attchments);
    }
    if (dataWithReplacements.dimTagPr && dataWithReplacements.dimTagPr.length > 0) {
        var mappingData = dataWithReplacements.dimTagPr.map(function(v) {
            return v.name;
        });
        setTagSelected(mappingData);
    }
    if (dataWithReplacements.dimNewPurchaseReqItem && dataWithReplacements.dimNewPurchaseReqItem.length > 0) {
        var productItems = dataWithReplacements.dimNewPurchaseReqItem.map(function(v) {
            var _dimProductPrice_dimProduct, _dimProductPrice_dimProduct1, _dimProductPrice_dimProduct2;
            var dim_product_price_id = v.dim_product_price_id, quantity = v.quantity, unit_price = v.unit_price, unit_id = v.unit_id, ratio_unit = v.ratio_unit, container = v.container, dim_mst_discount_type_id = v.dim_mst_discount_type_id, remark = v.remark, discount_value = v.discount_value, discount_price = v.discount_price, dimWarehouse = v.dimWarehouse, dimProductPrice = v.dimProductPrice, dimMasterUnit = v.dimMasterUnit, dimMstVat = v.dimMstVat;
            // console.log(discount_price);
            var mappingData = {
                rowID: nanoid(),
                productID: dim_product_price_id,
                productCode: (_dimProductPrice_dimProduct = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct === void 0 ? void 0 : _dimProductPrice_dimProduct.sku,
                productName: (_dimProductPrice_dimProduct1 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct1 === void 0 ? void 0 : _dimProductPrice_dimProduct1.name_th,
                productBarcode: dimProductPrice.barcode,
                productUnitID: unit_id,
                productRatioUnit: ratio_unit,
                productContainer: container,
                supplierCode: (_dimProductPrice_dimProduct2 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct2 === void 0 ? void 0 : _dimProductPrice_dimProduct2.supplier_code,
                warehouseID: dimWarehouse.id,
                warehouseName: dimWarehouse.name,
                productUnit: dimMasterUnit ? dimMasterUnit.name : "-",
                productQty: quantity,
                productOrdered: "",
                ums: "",
                productStock: "",
                productPrice: unit_price,
                discountType: dim_mst_discount_type_id,
                discount: discount_price,
                discountPercent: discount_value,
                tax: "",
                price_before_tax: 0,
                dim_mst_vat_id: dimMstVat.id,
                vat: dimMstVat.value,
                note: remark,
                price_after_tax: 0,
                not_approve_reason: "",
                is_checked: false,
                edit: false
            };
            return mappingData;
        });
        dataWithReplacements.products = productItems;
        setProductRow(productItems);
    }
    if (dataWithReplacements.dimMstNewStatus && dataWithReplacements.dimMstNewStatus.id > 1) {
        setFormdisabled(true);
    }
    dataWithReplacements.dim_mst_new_status_id = "";
    dataWithReplacements.dim_new_item_pr_sorts = [];
    if (dataWithReplacements.dimNewItemPrSort && dataWithReplacements.dimNewItemPrSort.length > 0) {
        var mappingCols = dataWithReplacements.dimNewItemPrSort.map(function(v) {
            return {
                id: v.dimMstNewItemPrCol.id,
                sortKey: v.dimMstNewItemPrCol.name
            };
        });
        while(mappingCols.length <= 3){
            mappingCols.push({
                id: "",
                sortKey: ""
            });
        }
        setColumnsort(_object_spread_props(_object_spread({}, columnsort), {
            columnsort: mappingCols
        }));
    }
    return dataWithReplacements;
}
export function isDisabledEditPr(param) {
    var dimMstNewStatus = param.dimMstNewStatus;
    var disabledList = [
        6
    ];
    if (dimMstNewStatus && disabledList.includes(parseInt(dimMstNewStatus.id))) {
        return true;
    }
    return false;
}
