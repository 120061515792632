import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    data: null,
    isFetching: false,
    isError: false
};
var updateNewAlldernowEmpSlice = createSlice({
    name: "updateNewAlldernowEmp",
    initialState: initialState,
    reducers: {
        fetchData: function(state) {
            state.isFetching = true;
            state.data = null;
            state.isError = false;
        },
        fetchDataSuccess: function(state, action) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                data: action.payload
            });
        },
        fetchDataFailure: function(state) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                isError: true
            });
        },
        resetState: function() {
            return initialState;
        }
    }
});
var _updateNewAlldernowEmpSlice_actions = updateNewAlldernowEmpSlice.actions;
export var fetchData = _updateNewAlldernowEmpSlice_actions.fetchData, fetchDataSuccess = _updateNewAlldernowEmpSlice_actions.fetchDataSuccess, fetchDataFailure = _updateNewAlldernowEmpSlice_actions.fetchDataFailure, resetState = _updateNewAlldernowEmpSlice_actions.resetState;
export default updateNewAlldernowEmpSlice.reducer;
