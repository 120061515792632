import { generateRandomString } from "utils/HelperFunction";
export var initialState = {
    emp_code: "",
    dim_mst_prefix_id: "",
    first_name_th: "",
    last_name_th: "",
    first_name_en: "",
    last_name_en: "",
    id_card: "",
    nick_name: "",
    dim_mst_sex_id: "",
    birth_date: "",
    age: "",
    ethnicity: "",
    nationality: "",
    dim_mst_marital_status_id: "",
    dim_mst_military_status_id: "",
    dim_mst_relationship_id: "",
    address: "",
    sub_district: "",
    district: "",
    province: "",
    postal_code: "",
    email: "",
    phone_number: "",
    line: "",
    facebook: "",
    instagram: "",
    emergency_contact_person: "",
    emergency_phone_number: "",
    dim_mst_bank_id: "",
    dim_mst_bank_account_type_id: "",
    is_bank_account: false,
    bank_account_name: "",
    bank_account_number: "",
    bank_account_branch_name: "",
    bank_account_branch_number: "",
    dim_mst_employee_type_id: "",
    dim_mst_department_id: "",
    dim_mst_position_id: "",
    start_date: "",
    end_date: "",
    is_register_social_security: false,
    img_file: null,
    doc_files: []
};
export var testState = {
    emp_code: "EMP".concat(generateRandomString(5).toLowerCase()),
    dim_mst_prefix_id: "1",
    first_name_th: "สมชาย",
    last_name_th: "ทดสอบ",
    first_name_en: "Somchai",
    last_name_en: "Test",
    id_card: "1234567890123",
    nick_name: "Tom",
    dim_mst_sex_id: "1",
    birth_date: "1990-01-01",
    age: "33",
    ethnicity: "ไทย",
    nationality: "ไทย",
    dim_mst_marital_status_id: 1,
    dim_mst_military_status_id: 2,
    dim_mst_relationship_id: "3",
    address: "123 ถนนทดสอบ",
    sub_district: "ตำบลทดสอบ",
    district: "อำเภอทดสอบ",
    province: "จังหวัดทดสอบ",
    postal_code: "12345",
    email: "".concat(generateRandomString(6).toLowerCase(), ".test@example.com"),
    phone_number: "0987654321",
    line: "somchaitest",
    facebook: "somchaiTestFB",
    instagram: "somchaiTestIG",
    emergency_contact_person: "สมหญิง ทดสอบ",
    emergency_phone_number: "0912345678",
    dim_mst_bank_id: "1",
    dim_mst_bank_account_type_id: "1",
    is_bank_account: true,
    bank_account_name: "สมชาย ทดสอบ",
    bank_account_number: "1234567890",
    bank_account_branch_name: "สาขาใจกลางเมือง",
    bank_account_branch_number: "001",
    dim_mst_employee_type_id: "1",
    dim_mst_department_id: "1",
    dim_mst_position_id: "1",
    start_date: "2023-01-01",
    end_date: "",
    is_register_social_security: true
};
