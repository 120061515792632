import { domain } from "constants/server/api";
import join from "url-join";
export function getFullUrlPath(filePath) {
    var fullUrl = filePath;
    var isAbsoluteURLRegex = /^(?:\w+:)\/\//;
    if (!isAbsoluteURLRegex.test(filePath)) {
        fullUrl = join(domain, filePath);
    }
    return fullUrl;
}
export function getFileName(url) {
    var name = url.split("/").pop().split("?")[0].split("#")[0];
    return name;
}
