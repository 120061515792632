import { _ as _define_property } from "@swc/helpers/_/_define_property";
export var suplierData = {
    dim_mst_business_type_id: 1,
    dim_group_of_supplier_id: 1,
    dim_mst_prefix_id: 1,
    img_path: "path/to/image.jpg",
    tax_identification_number: "123456789",
    first_name: "John",
    last_name: "Doe",
    initials: "DIT",
    company_address: "123 Business St.",
    company_sub_district: "Business Bay",
    company_district: "Central Business",
    company_province: "Businessland",
    company_postal_code: "10000",
    company_phone_number: "1234567890",
    company_mobile_phone_number: "0987654321",
    company_email: "contact@business.com",
    company_website: "http://www.business.com",
    doc_delivery_address: "456 Delivery Ave.",
    doc_delivery_sub_district: "Delivery Bay",
    doc_delivery_district: "Central Delivery",
    doc_delivery_province: "Deliveryland",
    doc_delivery_postal_code: "20000",
    doc_delivery_phone_number: "1234567890",
    doc_delivery_mobile_phone_number: "0987654321",
    doc_delivery_email: "delivery@business.com",
    main_contact_first_name: "Jane",
    main_contact_last_name: "Smith",
    main_contact_nick_name: "Jay",
    main_contact_email: "jane.smith@business.com",
    main_contact_phone_number: "1234567890",
    main_contact_mobile_phone_number: "0987654321",
    main_contact_position: "Manager",
    alternate_contact_first_name: "Mike",
    alternate_contact_last_name: "Johnson",
    alternate_contact_nick_name: "Mikey",
    alternate_contact_email: "mike.johnson@business.com",
    alternate_contact_phone_number: "1234567890",
    alternate_contact_mobile_phone_number: "0987654321",
    alternate_contact_position: "Assistant Manager",
    dim_mst_payment_term_id: 1,
    is_credit_limit: true,
    received_credit_limit: 50000,
    line_token: "abcdef123456",
    is_active: true,
    created_by: "admin",
    created_at: "2023-01-01T12:00:00Z",
    updated_at: "2023-01-02T12:00:00Z",
    dim_mst_business_category_id: 1,
    dim_mst_payment_method_id: 1,
    dim_mst_company_prefix_id: 1,
    company_name: "Doe Industries",
    company_branch_code: "DI123",
    company_branch_name: "Doe Industries Central"
};
var _obj;
export var suplierInitData = (_obj = {
    dim_mst_business_type_id: 1,
    dim_group_of_supplier_id: "",
    dim_mst_prefix_id: "",
    img_path: "",
    code: "",
    tax_identification_number: "",
    first_name: "",
    last_name: "",
    company_address: "",
    company_sub_district: "",
    company_district: "",
    company_province: "",
    company_postal_code: "",
    company_phone_number: "",
    company_mobile_phone_number: "",
    company_email: "",
    company_website: "",
    doc_delivery_address: "",
    doc_delivery_sub_district: "",
    doc_delivery_district: "",
    doc_delivery_province: "",
    doc_delivery_postal_code: "",
    doc_delivery_phone_number: "",
    doc_delivery_mobile_phone_number: "",
    doc_delivery_email: "",
    main_contact_first_name: "",
    main_contact_last_name: "",
    main_contact_nick_name: "",
    main_contact_email: "",
    main_contact_phone_number: "",
    main_contact_mobile_phone_number: "",
    main_contact_position: "",
    alternate_contact_first_name: "",
    alternate_contact_last_name: "",
    alternate_contact_nick_name: "",
    alternate_contact_email: "",
    alternate_contact_phone_number: "",
    alternate_contact_mobile_phone_number: "",
    alternate_contact_position: "",
    dim_mst_payment_term_id: "",
    is_credit_limit: "",
    credit_days: 0,
    received_credit_limit: "",
    line_token: "",
    is_active: 1,
    created_by: "",
    created_at: "",
    updated_at: "",
    dim_mst_business_category_id: "",
    dim_mst_payment_method_id: "",
    dim_mst_company_prefix_id: "",
    company_name: "",
    company_branch_code: "",
    company_branch_name: ""
}, _define_property(_obj, "company_branch_name", ""), _define_property(_obj, "businessPrefix", ""), _define_property(_obj, "businessSuffix", ""), _obj);
