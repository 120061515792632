import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    data: null,
    isFetching: false,
    isError: false
};
var updateGroupOfSupplierSlice = createSlice({
    name: "updateGroupOfSupplier",
    initialState: initialState,
    reducers: {
        fetchData: function(state) {
            state.isFetching = true;
            state.data = null;
            state.isError = false;
        },
        fetchDataSuccess: function(state, action) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                data: action.payload
            });
        },
        fetchDataFailure: function(state) {
            return _object_spread_props(_object_spread({}, state), {
                isFetching: false,
                isError: true
            });
        },
        resetState: function() {
            return initialState;
        }
    }
});
var _updateGroupOfSupplierSlice_actions = updateGroupOfSupplierSlice.actions;
export var fetchData = _updateGroupOfSupplierSlice_actions.fetchData, fetchDataSuccess = _updateGroupOfSupplierSlice_actions.fetchDataSuccess, fetchDataFailure = _updateGroupOfSupplierSlice_actions.fetchDataFailure, resetState = _updateGroupOfSupplierSlice_actions.resetState;
export default updateGroupOfSupplierSlice.reducer;
