import { _ as _async_to_generator } from "@swc/helpers/_/_async_to_generator";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import axios from "axios";
import join from "url-join";
import { domain } from "constants/server/api";
import { NETWORK_CONNECTION_MESSAGE, NOT_CONNECT_NETWORK_CODE } from "../constants/server/error";
var isAbsoluteURLRegex = /^(?:\w+:)\/\//;
axios.interceptors.request.use(function() {
    var _ref = _async_to_generator(function(config) {
        return _ts_generator(this, function(_state) {
            if (!isAbsoluteURLRegex.test(config.url)) {
                config.url = join(domain, config.url);
            }
            config.timeout = 10 * 1000; // 10 Second
            return [
                2,
                config
            ];
        });
    });
    return function(config) {
        return _ref.apply(this, arguments);
    };
}());
axios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    } else if (!error.response) {
        return Promise.reject({
            code: NOT_CONNECT_NETWORK_CODE,
            message: NETWORK_CONNECTION_MESSAGE
        });
    } else if (error.response.status === 401) {
        localStorage.removeItem("accessToken");
    }
    return Promise.reject(error);
});
// axios.defaults.withCredentials = true;
export var http = axios;
