import { _ as _async_to_generator } from "@swc/helpers/_/_async_to_generator";
import { _ as _ts_generator } from "@swc/helpers/_/_ts_generator";
import * as getAllMstPaymentMethodActions from "../../slices/master/getAllMstPaymentMethod.slice";
import { http } from "utils/http";
import { endPoint } from "constants/server/api";
export var getAllMstPaymentMethod = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var config, result, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        dispatch(getAllMstPaymentMethodActions.fetchData());
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            ,
                            4
                        ]);
                        config = {
                            method: "get",
                            url: endPoint.MST_PAYMENT_METHOD
                        };
                        return [
                            4,
                            http(config)
                        ];
                    case 2:
                        result = _state.sent();
                        dispatch(getAllMstPaymentMethodActions.fetchDataSuccess(result.data));
                        return [
                            3,
                            4
                        ];
                    case 3:
                        error = _state.sent();
                        dispatch(getAllMstPaymentMethodActions.fetchDataFailure());
                        return [
                            3,
                            4
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
