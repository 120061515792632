import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { nanoid } from "@reduxjs/toolkit";
import { getFullUrlPath } from "./HandleFileFunction";
import { mappingGr, mappingLotReceives } from "./HelperGrFunction";
import { getGP, getMstProductBeforeVat } from "./HelperFunction";
export function mappingFinancial(param) {
    var grData = param.grData, action = param.action, setPdata = param.setPdata, setProductRow = param.setProductRow, setFormdisabled = param.setFormdisabled, columnsort = param.columnsort, setColumnsort = param.setColumnsort, setAttachments = param.setAttachments, setTagSelected = param.setTagSelected;
    var _dataWithReplacements_dimWarehouse, _dataWithReplacements_dimNewPurchaseOrder, _dataWithReplacements_dimMstFinancialStatus, _dataWithReplacements_dimMstFinancialStatus1;
    var dataWithReplacements = Object.entries(grData).reduce(function(acc, param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
        acc[key] = value === null ? "" : value;
        return acc;
    }, {});
    if (dataWithReplacements.dimSupplierInformation) {
        var dimSupplierInformation = dataWithReplacements.dimSupplierInformation;
        var full_supplier_name = dataWithReplacements.full_supplier_name, full_supplier_address_name = dataWithReplacements.full_supplier_address_name;
        setPdata(function(prev) {
            return {
                id: dimSupplierInformation.id,
                dimGroupOfSupplierCode: dimSupplierInformation.dimGroupOfSupplier ? dimSupplierInformation.dimGroupOfSupplier.code : "-",
                full_supplier_name: full_supplier_name,
                initials: dimSupplierInformation.initials,
                company_branch_code: dimSupplierInformation.company_branch_code,
                company_branch_name: dimSupplierInformation.company_branch_name,
                full_supplier_address_name: full_supplier_address_name,
                company_phone_number: dimSupplierInformation.company_phone_number
            };
        });
        dataWithReplacements.supplier_phone_number = dimSupplierInformation.company_phone_number;
        var _dimSupplierInformation_initials;
        dataWithReplacements.supplier_nickname = (_dimSupplierInformation_initials = dimSupplierInformation.initials) !== null && _dimSupplierInformation_initials !== void 0 ? _dimSupplierInformation_initials : "-";
    }
    if (dataWithReplacements.dimDocFileGr && dataWithReplacements.dimDocFileGr.length > 0) {
        var attchments = dataWithReplacements.dimDocFileGr.map(function(v, i) {
            var newId = nanoid();
            var type = v.original_file_name.split(".");
            var mappingData = {
                id: newId,
                docId: v.id,
                docFile: v.original_file_name,
                docFileType: type[type.length - 1],
                docFileData: "",
                docUrl: getFullUrlPath(v.file_path)
            };
            return mappingData;
        });
        setAttachments(attchments);
    }
    if (dataWithReplacements.dimTagGr && dataWithReplacements.dimTagGr.length > 0) {
        var mappingData = dataWithReplacements.dimTagGr.map(function(v) {
            return v.name;
        });
        setTagSelected(mappingData);
    }
    if (dataWithReplacements.dimNewGrItem && dataWithReplacements.dimNewGrItem.length > 0) {
        var productItems = dataWithReplacements.dimNewGrItem.map(function(v) {
            var _dimProductPrice_dimProduct, _dimProductPrice_dimProduct1, _dimProductPrice_dimProduct2, _dimProductPrice_dimProduct3;
            var id = v.id, dim_product_price_id = v.dim_product_price_id, po_split_quantity = v.po_split_quantity, unit_price = v.unit_price, unit_id = v.unit_id, ratio_unit = v.ratio_unit, container = v.container, dim_mst_discount_type_id = v.dim_mst_discount_type_id, remark = v.remark, discount_value = v.discount_value, discount_price = v.discount_price, dimWarehouse = v.dimWarehouse, dimProductPrice = v.dimProductPrice, dimMasterUnit = v.dimMasterUnit, dimMstVat = v.dimMstVat, received_quantity = v.received_quantity, receive_quantity = v.receive_quantity, remaining_quantity = v.remaining_quantity, dimGrLot = v.dimGrLot, dimNewPoSplitItem = v.dimNewPoSplitItem;
            // console.log(discount_price);
            var total_price_before_vat = dimNewPoSplitItem.total_price_before_vat, total_price_include_vat = dimNewPoSplitItem.total_price_include_vat;
            var productMstVat = (_dimProductPrice_dimProduct = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct === void 0 ? void 0 : _dimProductPrice_dimProduct.vat;
            var productMstPrice = dimProductPrice.price_1;
            var productMstPriceBeforeVat = getMstProductBeforeVat(productMstPrice, productMstVat === "VAT 7%");
            var mappingData = {
                rowID: nanoid(),
                productID: dim_product_price_id,
                grItemID: id,
                productCode: (_dimProductPrice_dimProduct1 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct1 === void 0 ? void 0 : _dimProductPrice_dimProduct1.sku,
                productName: (_dimProductPrice_dimProduct2 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct2 === void 0 ? void 0 : _dimProductPrice_dimProduct2.name_th,
                productBarcode: dimProductPrice.barcode,
                productUnitID: unit_id,
                productRatioUnit: ratio_unit,
                productContainer: container,
                supplierCode: (_dimProductPrice_dimProduct3 = dimProductPrice.dimProduct) === null || _dimProductPrice_dimProduct3 === void 0 ? void 0 : _dimProductPrice_dimProduct3.supplier_code,
                warehouseID: dimWarehouse.id,
                warehouseName: dimWarehouse.name,
                productUnit: dimMasterUnit ? dimMasterUnit.name : "-",
                productQty: receive_quantity,
                productOrdered: "",
                productOrderQuantity: po_split_quantity,
                productReceivedQuantity: received_quantity,
                productReceiveQuantity: receive_quantity,
                poSplitTotalBeforeVat: total_price_before_vat !== null && total_price_before_vat !== void 0 ? total_price_before_vat : 0,
                poSplitTotalIncludeVat: total_price_include_vat !== null && total_price_include_vat !== void 0 ? total_price_include_vat : 0,
                receivelist: mappingLotReceives(dimGrLot),
                productMstPriceBeforeVat: productMstPriceBeforeVat,
                ums: "",
                productStock: "",
                productPrice: unit_price,
                discountType: dim_mst_discount_type_id,
                discount: discount_price,
                discountPercent: discount_value,
                tax: "",
                price_before_tax: 0,
                dim_mst_vat_id: dimMstVat.id,
                vat: dimMstVat.value,
                note: remark,
                price_after_tax: 0,
                not_approve_reason: "",
                is_checked: false,
                edit: false
            };
            return mappingData;
        });
        dataWithReplacements.products = productItems;
        setProductRow(productItems);
    }
    dataWithReplacements.dim_mst_new_status_id = "";
    dataWithReplacements.dim_new_item_pr_sorts = [];
    if (dataWithReplacements.dimNewItemPrSort && dataWithReplacements.dimNewItemPrSort.length > 0) {
        var mappingCols = dataWithReplacements.dimNewItemPrSort.map(function(v) {
            return {
                id: v.dimMstNewItemPrCol.id,
                sortKey: v.dimMstNewItemPrCol.name
            };
        });
        while(mappingCols.length <= 3){
            mappingCols.push({
                id: "",
                sortKey: ""
            });
        }
        setColumnsort(_object_spread_props(_object_spread({}, columnsort), {
            columnsort: mappingCols
        }));
    }
    dataWithReplacements.dim_warehouse_name = (_dataWithReplacements_dimWarehouse = dataWithReplacements.dimWarehouse) === null || _dataWithReplacements_dimWarehouse === void 0 ? void 0 : _dataWithReplacements_dimWarehouse.name;
    dataWithReplacements.orderID = (_dataWithReplacements_dimNewPurchaseOrder = dataWithReplacements.dimNewPurchaseOrder) === null || _dataWithReplacements_dimNewPurchaseOrder === void 0 ? void 0 : _dataWithReplacements_dimNewPurchaseOrder.id;
    dataWithReplacements.financialStatusID = (_dataWithReplacements_dimMstFinancialStatus = dataWithReplacements.dimMstFinancialStatus) === null || _dataWithReplacements_dimMstFinancialStatus === void 0 ? void 0 : _dataWithReplacements_dimMstFinancialStatus.id;
    dataWithReplacements.financialStatusName = (_dataWithReplacements_dimMstFinancialStatus1 = dataWithReplacements.dimMstFinancialStatus) === null || _dataWithReplacements_dimMstFinancialStatus1 === void 0 ? void 0 : _dataWithReplacements_dimMstFinancialStatus1.name;
    dataWithReplacements.dim_mst_financial_status_id = "";
    return dataWithReplacements;
}
export function mappingProductItemUpdate(products) {
    var mappData = products.map(function(v) {
        return {
            id: v.grItemID,
            dim_product_price_id: v.productID,
            dim_warehouse_id: v.warehouseID,
            receive_quantity: v.productReceiveQuantity,
            po_split_quantity: v.productOrderQuantity,
            unit_price: v.productPrice,
            unit_id: v.productUnitID,
            ratio_unit: v.productRatioUnit,
            container: v.productContainer,
            dim_mst_discount_type_id: v.discountType,
            discount_value: v.discountType === 1 ? v.discountPercent : v.discount,
            dim_mst_vat_id: v.dim_mst_vat_id,
            remark: v.note
        };
    });
    return mappData;
}
export function getFinanJsonKeyValue() {
    var keysToProcess = [
        "dim_employee_attached_doc",
        "update_doc_types",
        "update_doc_ids",
        "tag_grs",
        "dim_new_gr_items",
        "update_gr_items"
    ];
    return keysToProcess;
}
export function isDisabledEditFinancial(param) {
    var dimMstFinancialStatus = param.dimMstFinancialStatus;
    if (!dimMstFinancialStatus) {
        return true;
    }
    var disabledList = [
        3,
        4,
        5
    ];
    if (dimMstFinancialStatus && disabledList.includes(parseInt(dimMstFinancialStatus.id))) {
        return true;
    }
    return false;
}
