import { _ as _instanceof } from "@swc/helpers/_/_instanceof";
var reportWebVitals = function(onPerfEntry) {
    if (onPerfEntry && _instanceof(onPerfEntry, Function)) {
        import("web-vitals").then(function(param) {
            var getCLS = param.getCLS, getFID = param.getFID, getFCP = param.getFCP, getLCP = param.getLCP, getTTFB = param.getTTFB;
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
        });
    }
};
export default reportWebVitals;
